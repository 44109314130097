import { createClient } from '@supabase/supabase-js'

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

// Create a single supabase client for interacting with your database
const supabase = createClient('https://avempzothnvvhwfqkvws.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImF2ZW1wem90aG52dmh3ZnFrdndzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTMxMDI0MzMsImV4cCI6MjAwODY3ODQzM30.IwnbTeBZEhfclmEPs_J0mnOX4-nvEv9ab36awWrFvuo')

// get data from the database
const { data, error } = await supabase
    .from('Snipes')
    .select('name, took, userID')

console.log(data)

// order the data by the number of snipes
const sortedData = data.sort((a, b) => b.took - a.took)

console.log(sortedData)

export default function SnipesList() {
    const listItems = sortedData.map(person =>
        <Card sx={{ mb: 1 }}>
            <CardContent style={{ padding: 0, margin: 0 }}>
                <ListItem sx={{ p: 2 }}>
                    <ListItemIcon>
                        {sortedData.indexOf(person) === 0 ? (
                            <EmojiEventsIcon style={{ color: 'gold' }} />
                        ) : sortedData.indexOf(person) === 1 ? (
                            <EmojiEventsIcon style={{ color: 'silver' }} />
                        ) : sortedData.indexOf(person) === 2 ? (
                            <EmojiEventsIcon style={{ color: 'bronze' }} />
                        ) : (
                            <Box pl={1}>
                                {sortedData.indexOf(person) + 1}
                            </Box>
                        )}
                    </ListItemIcon>
                    {sortedData.indexOf(person) === 0 ? (
                        <ListItemText primary={<strong>{person.name}</strong>} />
                    ) : sortedData.indexOf(person) === 1 ? (
                        <ListItemText primary={<strong>{person.name}</strong>} />
                    ) : sortedData.indexOf(person) === 2 ? (
                        <ListItemText primary={<strong>{person.name}</strong>} />
                    ) : (
                        <ListItemText primary={person.name} />
                    )}
                    <ListItemIcon>
                    {sortedData.indexOf(person) === 0 ? (
                        <ListItemText primary={<strong>{person.took}</strong>} />
                    ) : sortedData.indexOf(person) === 1 ? (
                        <ListItemText primary={<strong>{person.took}</strong>} />
                    ) : sortedData.indexOf(person) === 2 ? (
                        <ListItemText primary={<strong>{person.took}</strong>} />
                    ) : (
                        <ListItemText primary={person.took} />
                    )}
                        
                    </ListItemIcon>
                </ListItem>
            </CardContent>
        </Card>
    );
    return <ul>{listItems}</ul>;
}