import MainNavbar from './components/navbar';

import List from '@mui/material/List';

import SnipesList from './components/snipesList';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import Box from '@mui/material/Box';

import PersonOffIcon from '@mui/icons-material/PersonOff';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useState } from 'react';
import VictimsList from './components/victimsList';

function App() {
  const [showVictims, setShowVictims] = useState(false);

  function toggleVictims() {
    setShowVictims(!showVictims);
  }

  return (
    <div className="App">
      <MainNavbar />

      {/* Conditionally show different leaderboards */}
      {showVictims ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5" fontWeight="bold" sx={{ py: 2, px: 2 }}>
            Victims Leaderboard
          </Typography>

          <Button variant="outlined" sx={{ mr: 2 }} onClick={toggleVictims}>
            <AddAPhotoIcon sx={{ mr: 1 }} />
            Top Snipers
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5" fontWeight="bold" sx={{ py: 2, px: 2 }}>
            Snipes Leaderboard
          </Typography>

          <Button variant="outlined" sx={{ mr: 2 }} onClick={toggleVictims}>
            <PersonOffIcon sx={{ mr: 1 }} />
            Top Victims
          </Button>
        </div>
      )
      }


      {/* Legend for the leaderboard */}
      {showVictims ? (<div>
        <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
          <Typography sx={{ width: '40px', ml: 1, mr: 3 }}>Rank</Typography>
          <Typography sx={{ flex: 1 }}>Name</Typography>
          <Typography sx={{ width: '100px', textAlign: 'center', mr: 2 }}>Kills</Typography>
        </Box>

        <List sx={{ pt: 2, px: 2 }}>
          <VictimsList />
        </List>
      </div>) : (<div>
        <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
          <Typography sx={{ width: '40px', ml: 1, mr: 3 }}>Rank</Typography>
          <Typography sx={{ flex: 1 }}>Name</Typography>
          <Typography sx={{ width: '100px', textAlign: 'center', mr: 2 }}>Snipes</Typography>
        </Box>

        <List sx={{ pt: 2, px: 2 }}>
          <SnipesList />
        </List>
      </div>)}

      {/* Add made with love by Kevin Wang */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', py: 2 }}>
        <Typography variant="caption">
          Made with ❤️ by <span style={{ textDecoration: 'underline' }}><a href="http://www.kevindw.dev">Kevin Wang</a></span> for the V1 Community
        </Typography>
      </Box>


    </div>
  );
}

export default App;
